module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"commonmark":true,"footnotes":true,"pedantic":true,"gfm":true,"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-katex","gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PVfit","short_name":"PVfit","start_url":"/","background_color":"#3f51b5","theme_color":"#3f51b5","display":"standalone","icon":"src/images/pvfit-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"733f29bf4c9319b6864708081e24c23c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto Sans","Inconsolata","Material Icons"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
