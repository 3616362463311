/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { queue } from './src/actions/snackbar-queue';

// rmwc shared styles
import '@rmwc/button/styles';
import '@rmwc/textfield/styles';
import '@rmwc/select/styles';
import '@rmwc/icon/styles';
import '@rmwc/tabs/styles';
import '@rmwc/linear-progress/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/tooltip/styles';
import '@rmwc/radio/styles';

// global styles
import './src/styles/global.scss';

export const onServiceWorkerUpdateReady = () => {
  queue.notify({
    icon: 'update',
    title: 'Stay up to date. Click here for latest version.',
    timeout: -1,
    dismissesOnAction: true,
    actions: [
      {
        label: 'Reload',
        onClick: () => window.location.reload(),
      },
    ],
  });
};
